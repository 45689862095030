<template>
  <span v-if="user.information">
      <span @click="showBtn">
      <slot></slot>
    </span>
    <span :key="'ChangeMeeting_' + this.showIndex">
      <JitsiMeeting    
        :message_uuid="message.message_uuid"
        :is-change="isChange"
        :change-attachments="changeAttachments"
        :change-time="changeTime"
        v-if="message.message_type == 'jitsi-meeting'"
        :show="show"
        :startMeeting="startMeeting"
        :endMeeting="endMeeting"
        @ChangeShow="ChangeShow"
        @SentMeeting="SentMeeting"
      ></JitsiMeeting>
      <JitsiConfidentiallyMeeting
        :message_uuid="message.message_uuid"
        :is-change="isChange"
        :change-attachments="changeAttachments"
        :change-time="changeTime"
        v-if="message.message_type == 'jitsi-secure-meeting'"
        :show="show"
        :startMeeting="startMeeting"
        :endMeeting="endMeeting"
        @ChangeShow="ChangeShow"
        @SentMeeting="SentMeeting"
      ></JitsiConfidentiallyMeeting>
      <NuiteqMeeting
        :message_uuid="message.message_uuid"
        :is-change="isChange"
        :change-attachments="changeAttachments"
        :change-time="changeTime"
        v-if="message.message_type == 'nuiteq-meeting'"
        :show="show"
        :startMeeting="startMeeting"
        :endMeeting="endMeeting"
        @ChangeShow="ChangeShow"
        @SentMeeting="SentMeeting"
      ></NuiteqMeeting>
      <TeamsMeeting
        :message_uuid="message.message_uuid"
        :is-change="isChange"
        :change-attachments="changeAttachments"
        :change-time="changeTime"
        v-if="message.message_type == 'teams-meeting'"
        :show="show"
        :startMeeting="startMeeting"
        :endMeeting="endMeeting"
        @ChangeShow="ChangeShow"
        @SentMeeting="SentMeeting"
      ></TeamsMeeting>
    </span>
  </span>
</template>
<script>
import JitsiMeeting from "./Meeting/JitsiMeeting";
import JitsiConfidentiallyMeeting from "./Meeting/JitsiConfidentiallyMeeting";
import NuiteqMeeting from "./Meeting/NuiteqMeeting";
import TeamsMeeting from "./Meeting/TeamsMeeting";
export default {
  props: {
    isChange: {
      type: Boolean,
      validator(value) {
        return value != undefined;
      },
    },
    changeAttachments: {
      type: Boolean,
      validator(value) {
        return value != undefined;
      },
    },
    changeTime: {
      type: Boolean,
      validator(value) {
        return value != undefined;
      },
    },
    message: {
      default: null,
      type: Object,
    },
    recurrence: {
      default: null,
      type: Object
    },
    startMeeting: {
      default: "",
      type: String,
    },
    endMeeting: {
      default: "",
      type: String,
    }
  },
  components: {
    NuiteqMeeting,
    JitsiMeeting,
    JitsiConfidentiallyMeeting,
    TeamsMeeting
  },
  data() {
    return {
      show: false,
      showIndex: 0
    };
  },
  methods: {
    showBtn() {
      this.show = true;
    },
    ChangeShow(val) {
      this.show = val;
      this.showIndex++;
    },
    SentMeeting() {
      this.$emit("Changed");
      this.showIndex++;
    }
  },
  computed: {
  },
  watch: {
  },
  mounted() {
  },
};
</script>

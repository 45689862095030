var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.user.information
    ? _c("span", [
        _c("span", { on: { click: _vm.showBtn } }, [_vm._t("default")], 2),
        _c(
          "span",
          { key: "ChangeMeeting_" + this.showIndex },
          [
            _vm.message.message_type == "jitsi-meeting"
              ? _c("JitsiMeeting", {
                  attrs: {
                    message_uuid: _vm.message.message_uuid,
                    "is-change": _vm.isChange,
                    "change-attachments": _vm.changeAttachments,
                    "change-time": _vm.changeTime,
                    show: _vm.show,
                    startMeeting: _vm.startMeeting,
                    endMeeting: _vm.endMeeting,
                  },
                  on: {
                    ChangeShow: _vm.ChangeShow,
                    SentMeeting: _vm.SentMeeting,
                  },
                })
              : _vm._e(),
            _vm.message.message_type == "jitsi-secure-meeting"
              ? _c("JitsiConfidentiallyMeeting", {
                  attrs: {
                    message_uuid: _vm.message.message_uuid,
                    "is-change": _vm.isChange,
                    "change-attachments": _vm.changeAttachments,
                    "change-time": _vm.changeTime,
                    show: _vm.show,
                    startMeeting: _vm.startMeeting,
                    endMeeting: _vm.endMeeting,
                  },
                  on: {
                    ChangeShow: _vm.ChangeShow,
                    SentMeeting: _vm.SentMeeting,
                  },
                })
              : _vm._e(),
            _vm.message.message_type == "nuiteq-meeting"
              ? _c("NuiteqMeeting", {
                  attrs: {
                    message_uuid: _vm.message.message_uuid,
                    "is-change": _vm.isChange,
                    "change-attachments": _vm.changeAttachments,
                    "change-time": _vm.changeTime,
                    show: _vm.show,
                    startMeeting: _vm.startMeeting,
                    endMeeting: _vm.endMeeting,
                  },
                  on: {
                    ChangeShow: _vm.ChangeShow,
                    SentMeeting: _vm.SentMeeting,
                  },
                })
              : _vm._e(),
            _vm.message.message_type == "teams-meeting"
              ? _c("TeamsMeeting", {
                  attrs: {
                    message_uuid: _vm.message.message_uuid,
                    "is-change": _vm.isChange,
                    "change-attachments": _vm.changeAttachments,
                    "change-time": _vm.changeTime,
                    show: _vm.show,
                    startMeeting: _vm.startMeeting,
                    endMeeting: _vm.endMeeting,
                  },
                  on: {
                    ChangeShow: _vm.ChangeShow,
                    SentMeeting: _vm.SentMeeting,
                  },
                })
              : _vm._e(),
          ],
          1
        ),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.message
    ? _c("span", [
        _vm.showComponent
          ? _c("span", [
              _c(
                "span",
                { on: { click: _vm.showBtn } },
                [_vm._t("default")],
                2
              ),
              _c(
                "span",
                { key: "AddMessageReply_" + _vm.showIndex },
                [
                  _vm.message.message_type != "sdk-message" &&
                  _vm.message.message_type != "fax-message"
                    ? _c("AddSefosMessage", {
                        attrs: {
                          isComplete: _vm.replyComplete,
                          isReply: _vm.reply,
                          isReplyAll: _vm.replyAll,
                          user_uuid: _vm.user_uuid,
                          functionbox_uuid: _vm.functionbox_uuid,
                          message_uuid: _vm.message.message_uuid,
                          show: _vm.show,
                        },
                        on: {
                          ChangeShow: _vm.ChangeShow,
                          SentMessage: _vm.SentMessage,
                        },
                      })
                    : _vm._e(),
                  _vm.message.message_type == "sdk-message"
                    ? _c("AddSDKMessage", {
                        attrs: {
                          isComplete: _vm.replyComplete,
                          isReply: _vm.reply,
                          isReplyAll: _vm.replyAll,
                          message_uuid: _vm.message.message_uuid,
                          functionbox_uuid: _vm.functionbox_uuid,
                          show: _vm.show,
                        },
                        on: {
                          ChangeShow: _vm.ChangeShow,
                          SentMessage: _vm.SentMessage,
                        },
                      })
                    : _vm._e(),
                  _vm.message.message_type == "fax-message"
                    ? _c("AddFaxMessage", {
                        attrs: {
                          isComplete: _vm.replyComplete,
                          isReply: _vm.reply,
                          isReplyAll: _vm.replyAll,
                          message_uuid: _vm.message.message_uuid,
                          functionbox_uuid: _vm.functionbox_uuid,
                          show: _vm.show,
                        },
                        on: {
                          ChangeShow: _vm.ChangeShow,
                          SentMessage: _vm.SentMessage,
                        },
                      })
                    : _vm._e(),
                ],
                1
              ),
            ])
          : _vm._e(),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
  <span v-if="message">
    <span v-if="showComponent">
      <span @click="showBtn">
        <slot></slot>
      </span>
      <span :key="'AddMessageReply_' + showIndex">
        <AddSefosMessage
          v-if="
            message.message_type != 'sdk-message' &&
            message.message_type != 'fax-message'
          "
          :isComplete="replyComplete"
          :isReply="reply"
          :isReplyAll="replyAll"
          :user_uuid="user_uuid"
          :functionbox_uuid="functionbox_uuid"
          :message_uuid="message.message_uuid"
          :show="show"
          @ChangeShow="ChangeShow"
          @SentMessage="SentMessage"
        ></AddSefosMessage>

        <AddSDKMessage
          v-if="message.message_type == 'sdk-message'"
          :isComplete="replyComplete"
          :isReply="reply"
          :isReplyAll="replyAll"
          :message_uuid="message.message_uuid"
          :functionbox_uuid="functionbox_uuid"
          :show="show"
          @ChangeShow="ChangeShow"
          @SentMessage="SentMessage"
        ></AddSDKMessage>

        <AddFaxMessage
          v-if="message.message_type == 'fax-message'"
          :isComplete="replyComplete"
          :isReply="reply"
          :isReplyAll="replyAll"
          :message_uuid="message.message_uuid"
          :functionbox_uuid="functionbox_uuid"
          :show="show"
          @ChangeShow="ChangeShow"
          @SentMessage="SentMessage"
        ></AddFaxMessage
      ></span>
    </span>
  </span>
</template>
<script>
import AddSefosMessage from "./Message/SefosMessage";
import AddSDKMessage from "./Message/SDKMessage";
import AddFaxMessage from "./Message/FaxMessage";
export default {
  props: {
    replyAll: {
      type: Boolean,
      validator(value) {
        return value != undefined;
      },
    },
    reply: {
      type: Boolean,
      validator(value) {
        return value != undefined;
      },
    },
    replyComplete: {
      type: Boolean,
      validator(value) {
        return value != undefined;
      },
    },
    functionbox_uuid: {
      default: "",
      type: String,
    },
    message: {
      default: null,
      type: Object,
    },
    user_uuid: {
      default: "",
      type: String,
    },
    external_system_participants: {
      default: null,
      type: Array,
    },
  },
  components: {
    AddSefosMessage,
    AddSDKMessage,
    AddFaxMessage,
  },
  data() {
    return {
      showIndex: 0,
      showComponent: true,
      show: false,
    };
  },
  methods: {
    showBtn() {
      this.show = true;
    },
    ChangeShow(val) {
      this.showIndex++;
      this.show = val;
    },
    SentMessage() {
      this.showIndex++;
      this.$emit("Changed");
    },
  },
  mounted() {},
};
</script>